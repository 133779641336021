import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import Link from 'react/generic/link/Link';
import Logo from 'react/generic/logo/Logo';
import Button from 'react/generic/button/Button';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import messages, { errorMessages } from './error-page.messages';
import classNames from './error-page.module.scss';

export const ERRORS = ['401', '403', '404', '500'];

const ErrorPage = ({ code, errorMessage }) => {
  const errorCode = ERRORS.includes(code) ? code : '';
  const defaultErrorMessage = ERRORS.includes(code) ? errorMessages[code] : messages.DEFAULT_ERROR;

  return (
    <div className={classNames.container}>
      <FormattedMessageChildren {...messages.TITLE} values={{ errorCode }}>
        {message => (
          <Helmet>
            <title>{message}</title>
          </Helmet>
        )}
      </FormattedMessageChildren>
      <div className={classNames.codeContainer}>
        <div className={classNames.code}>
          {/* Using a svg is the only way to make the text fit the container size responsively. */}
          {/* Viewbox values are arbitrary to display the text completly. */}
          <svg viewBox="0 0 25 11">
            <text fontWeight="300" fill="currentColor" x="0" y="10">{errorCode}</text>
          </svg>
        </div>
      </div>

      <div className={classNames.content}>
        <div className={classNames.logo}>
          <Link to="/">
            <Logo name="full" />
          </Link>
        </div>

        <div className={classNames.text}>
          <div className={classNames.big}>
            <FormattedMessage {...messages.OOPS} />
          </div>
          <div>
            {errorMessage || <FormattedMessage {...defaultErrorMessage} />}
          </div>
        </div>

        <div className={classNames.buttonContainer}>
          <Link to="/">
            <Button
              overrideClassName={classNames.button}
              overrideClassNames={classNames}
            >
              <FormattedMessage {...messages.GO_HOME} />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  code: PropTypes.string,
  errorMessage: PropTypes.node,
};

ErrorPage.defaultProps = {
  code: null,
  errorMessage: null,
};

export default ErrorPage;
